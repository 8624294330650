.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/************************* REACT CALENDAR *****************************/
.react-calendar {
  width: 390px;
  max-width: 100%;
  background: white;
  border-top: 1px solid #828282;
  font-family: 'Lato', 'Inter', 'Jaldi', 'Roboto', "Helvetica Neue", Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 805px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 390px;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 80px;
  border-bottom: 1px solid #828282;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation__label__labelText {
  color: #828282;
  font-family: 'Lato', sans-serif; 
  font-size: 20px; 
  font-weight: 500;
  text-transform: capitalize;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Lato';
  font-size: 12px;
  font-weight: 500;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 1em;
  text-decoration: none;
  margin-left: 5px;
  max-width: 50px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  border: none;
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #525252;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__month-view__days__day {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
}


.react-calendar__tile {
  max-width: 50px;
  background: none;
  text-align: center;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 500;
  margin-inline: 5px;
  margin-block: 2.5px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #828282;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  border-radius: 30px;
  background-color: #828282;
  color: white;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background:#2C5F8A;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2C5F8A;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.bg-blue {
  background-color: #3F88C5;
}
.bg-green {
  background-color: #4DA167;
}
.bg-pink {
  background-color: #FF3C83;
}
